import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest,} from '@angular/common/http';

import {BehaviorSubject, Observable, of, throwError} from 'rxjs';
import {catchError, filter, switchMap} from 'rxjs/operators';

import { IdamService } from './idam.service';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { authActions } from 'src/app/store/auth/auth.actions';

const TOKEN_HEADER_KEY = 'Authorization';

@Injectable({
  providedIn: 'root'
})
export class TokenInterceptor implements HttpInterceptor {

  // private isRefreshing = false;
  private isRefreshing$: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(
    private idamService: IdamService
    , private router: Router,
    private store: Store
  ) {

  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<any> {
    if (req.urlWithParams.startsWith('/assets') || req.url.endsWith('json')) {
      return next.handle(req);
    }
    let authReq = req.clone({ withCredentials: true });
    const token = this.idamService.tokenValue;
    if (token != null) {
      authReq = this.addTokenHeader(req, token);
    }
    return next.handle(authReq)
    .pipe(
      catchError(error => {
      // eslint-disable-next-line no-console
      console.error('an error intercepted at url', req.urlWithParams);
      if (error instanceof HttpErrorResponse && this.isErrorTypeJwtExpired(error)) {
        return this.handle401Error(authReq, next);
      }
      if (this.isRefreshTokenExpired(error)) {

            // eslint-disable-next-line no-console
            console.error('Refresh token expired...');
            localStorage.clear();
            void this.router.navigateByUrl('/login');
            return of(null);
          }
          return throwError(error);
        }));
  }


  private isErrorTypeJwtExpired(error: HttpErrorResponse): boolean {
    return (error.error.message === 'Authorizer: jwt expired' && error.status === 403);
  }

  private isRefreshTokenExpired(error: HttpErrorResponse): boolean {
    return error.error.code === 'refresh_token_expired' && error.status === 401;
  }

  private addTokenHeader(request: HttpRequest<any>, token: string): any {

    return request.clone({headers: request.headers.set(TOKEN_HEADER_KEY, `Bearer ${token}`)});
  }

  private handle401Error(request: HttpRequest<any>, next: HttpHandler): any {
    if (!this.isRefreshing$.value) {
      this.isRefreshing$.next(true);

      const token = this.idamService.tokenValue;
      const refreshToken = this.idamService.refreshTokenValue;
      if (token) {
        return this.idamService.refreshToken({token, refreshToken}).pipe(
          switchMap((refreshedToken) => {
            if (refreshedToken){
              this.idamService.setToken(refreshedToken.token, refreshedToken.refreshToken);
              this.store.dispatch(authActions.refreshingTokenSuccess({token: refreshedToken.token, refreshToken: refreshedToken.refreshToken}));

              return next.handle(this.addTokenHeader(request, refreshedToken.token));
            }

            throw new Error('Refresh token failed');


          }),
          catchError((err) => {


            localStorage.clear();
            void this.router.navigateByUrl('/auth/welcome');
            this.isRefreshing$.next(false);
            return throwError(()=>err);
          })
        );
      }
    }else{
      return this.isRefreshing$.pipe(
        filter(result => result === false),
        switchMap(result=>{
          if (!result){
            return next.handle(this.addTokenHeader(request, this.idamService.tokenValue));
          }else{
            throw new Error('Token not refreshed');
          }
        })
      );
    }
  }
}


