import { Injectable, inject } from '@angular/core';
import { RestService } from '../shared/services/rest.service';

import { catchError, Observable, of } from 'rxjs';
import { TopicSuperAdminDto } from '../models/topics';
import { map } from "rxjs/operators";
import { NotificationService } from "../shared/services/notification.service";

@Injectable({ providedIn: 'root' })
export class TopicsService {
  private restService = inject(RestService);
  private notifyService = inject(NotificationService);

  // getAll(): Observable<CityBaseDto[]> {
  //   return this.restService.get$<CityBaseDto[]>('city/get-all', true, 'news');
  // }

  getAdminAll(): Observable<TopicSuperAdminDto[]> {
    return this.restService.get$<TopicSuperAdminDto[]>('admin/topic/get-all', true, 'newsprovider');
  }

  createTopic(name: string, order: number): Observable<TopicSuperAdminDto> {
    return this.restService.post$<TopicSuperAdminDto>('admin/topic/create', {name: name, order: order}, true, 'newsprovider');
  }

  deleteTopic(id: number): Observable<boolean> {
    return this.restService.delete$(`admin/topic/delete/${id}`, 'newsprovider').pipe(
      map(()=> true),
      catchError((err)=>{
        if (err.error){
          this.notifyService.showError('Topic NON cancellato! ');
        }
        return of(false);
      })
    );
  }

  updateTopic(id: number, newName: string, order: number): Observable<TopicSuperAdminDto> {
    return this.restService.put$<TopicSuperAdminDto>(`admin/topic/update/${id}`, { name: newName, order: order }, 'newsprovider');
  }
}
