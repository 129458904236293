import { createActionGroup, emptyProps, props } from "@ngrx/store";

export const authActions = createActionGroup({
  source: 'Login',
  events: {
    'Create new Token': props<{ token: string }>(),
    'Login Success': props<{ token: string; refreshToken: string; redirect: boolean; }>(),
    'Check If User Was Logged': emptyProps(),
    'On Logout': emptyProps(),
    'Logged Out': emptyProps(),
    'Refreshing Token': props<{ token: string; refreshToken: string }>(),
    'Refreshing token success': props<{ token: string; refreshToken: string }>(),
    'Refreshing token failed': emptyProps(),
    'Go To Account': props<{ code: string, token: string; refreshToken: string }>(),
  }
});



