import { inject } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, switchMap, tap } from 'rxjs/operators';
import { authActions } from './auth.actions';
import { IdamService } from 'src/app/services/idam.service';

export const createNewToken = createEffect(
  (actions$ = inject(Actions), idamService = inject(IdamService)) => {
    return actions$.pipe(
      ofType(authActions.createNewToken),
      switchMap((action) => idamService.createNewToken(action.token)),
      tap((response) => {
        if (response) {
          idamService.setToken(response.token, response.refreshToken);
        }
      }),
      switchMap((result) => {
        if (result) {
          return of(
            authActions.loginSuccess({
              token: result.token,
              refreshToken: result.refreshToken,
              redirect: true,
            })
          );
        } else {
          return of(authActions.refreshingTokenFailed());
        }
      }),
      catchError(() => of(authActions.refreshingTokenFailed()))
    );
  },
  {
    functional: true,
  }
);

export const login = createEffect(
  (
    actions$ = inject(Actions),
    router = inject(Router),
    idamService = inject(IdamService)
  ) => {
    return actions$.pipe(
      ofType(authActions.loginSuccess),
      tap((action) => {
        idamService.setToken(action.token, action.refreshToken);

        if (action.redirect) {
          void router.navigate(['news/dashboard']);
        }
      })
    );
  },
  { dispatch: false, functional: true }
);

export const checkingIfUserIsLoggedIn = createEffect(
  (actions$ = inject(Actions), idamService = inject(IdamService)) => {
    return actions$.pipe(
      ofType(authActions.checkIfUserWasLogged),
      switchMap(() => {
        if (idamService.isLogged()) {
          return [
            authActions.loginSuccess({
              token: idamService.tokenValue,
              refreshToken: idamService.refreshTokenValue,
              redirect: false,
            }),
            //CompanyActions.loadCompany(),
          ];
        } else {
          return [authActions.loggedOut()];
        }
      })
    );
  },
  { dispatch: true, functional: true }
);

// @Injectable()
// export class AuthEffects {

// onLogout$ = createEffect(() => {
//   return this.actions$.pipe(
//     ofType(authActions.onLogout),

//     switchMap(() => this.idamService.logout()),
//     switchMap(() => of(authActions.loggedOut()))
//   )
// }
// );

// logout$ = createEffect(
//   () => {
//     return this.actions$.pipe(
//       ofType(authActions.onLogout),
//       tap(() => {
//         void this.router.navigateByUrl('/auth/welcome');
//       })
//     )
//   },
//   { dispatch: false }
// );

// refreshingToken$ = createEffect(() => {
//   return this.actions$.pipe(
//     ofType(authActions.refreshingToken),
//     switchMap((action) =>
//       this.idamService.refreshToken({ token: action.token, refreshToken: action.refreshToken, urlWeb: '' })
//     ),
//     tap((action) => {
//       localStorage.setItem('refreshToken', action.refreshToken);
//       localStorage.setItem('token', action.token);
//     }),
//     switchMap((result) => of(authActions.refreshingTokenSuccess({ ...result }))),
//     catchError(() => of(authActions.refreshingTokenFailed()))
//   )
// }
// );

// goToAccount$ = createEffect(() => {
//   return this.actions$.pipe(
//     ofType(authActions.goToAccount),
//     switchMap((action) =>
//       this.idamService.refreshToken({ token: action.token, refreshToken: action.refreshToken, urlWeb: '' }).pipe(
//         tap((res) => {
//           if (res) {
//             window.location.href = `https://${action.code}.account${environment.ambient === '' ? '.' : '.' + environment.ambient + '.'
//               }mobilitycompany.it/auth/welcome?token=${res.token}&refreshToken=${res.refreshToken}`;
//           }
//         })
//       )
//     ),
//     tap((action) => {
//       localStorage.setItem('refreshToken', action.refreshToken);
//       localStorage.setItem('token', action.token);
//     }),
//     switchMap((result) => of(authActions.refreshingTokenSuccess({ ...result }))),
//     catchError((err) => of(authActions.refreshingTokenFailed()))
//   );
// });
//   constructor(private actions$: Actions, private router: Router, private idamService: IdamService) { }
// }
