import { Injectable, inject } from '@angular/core';
import { RestService } from '../shared/services/rest.service';

import { Observable } from 'rxjs';
import { CityBaseDto, CitySuperAdminDto } from '../models/citybaseDto';

@Injectable({providedIn: 'root'})
export class CitiesService {
  private restService = inject(RestService);


  getAll(): Observable<CityBaseDto[]>{
    return this.restService.get$<CityBaseDto[]>('city/get-all', true, 'news');
  }

  getAdminAll(): Observable<CitySuperAdminDto[]>{
    return this.restService.get$<CitySuperAdminDto[]>('admin/city/get-all', true, 'newsprovider');
  }

  createCity(name: string): Observable<CitySuperAdminDto>{
    return this.restService.post$<CitySuperAdminDto>('admin/city/create', { name }, true, 'newsprovider');
  }

  deleteCity(id: number): Observable<string>{
    return this.restService.delete$(`admin/city/delete/${id}`, 'newsprovider');
  }

  updateCity(id: number, newName: string): Observable<CitySuperAdminDto> {
    return this.restService.put$<CitySuperAdminDto>(`admin/city/update/${id}`,{name: newName},  'newsprovider');
  }


}
