import { Component, OnInit, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import { authActions } from './store/auth/auth.actions';
import { distinctUntilChanged, filter, tap, timer } from 'rxjs';
import {
  selectTokenAndRefreshToken,
} from './store/auth/auth.reducer';
import { MatDialogModule } from '@angular/material/dialog';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import {
  DateFnsAdapter,
  MAT_DATE_FNS_FORMATS,
} from '@angular/material-date-fns-adapter';
import { it } from 'date-fns/locale';
import { JwtHelperService } from '@auth0/angular-jwt';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, MatDialogModule],
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    { provide: MAT_DATE_LOCALE, useValue: it },
    { provide: DateAdapter, useClass: DateFnsAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_DATE_FNS_FORMATS },
  ],
})
export class AppComponent implements OnInit {
  title = 'Admin News';

  private store = inject(Store);

  ngOnInit(): void {
    this.store.dispatch(authActions.checkIfUserWasLogged());
    this.store
      .select(selectTokenAndRefreshToken)
      .pipe(
        //first(),
        distinctUntilChanged(),
        filter((tr) => tr.isRefreshingToken === false)
      )
      .subscribe((tr) => {
        if (tr && tr.token && tr.token !== '') {
          const helper = new JwtHelperService();

          const expiry = helper.getTokenExpirationDate(tr.token);
          console.log('refreshing token at ', expiry!.toISOString());
          timer(expiry!)
            .pipe(
              tap(() => {
                console.log('refreshing....');
              })
              // first()
            )
            .subscribe(() => {
              this.store.dispatch(
                authActions.refreshingToken({
                  token: tr.token!,
                  refreshToken: tr.refreshToken!,
                })
              );
            });
        }
      });
  }
}
