import { createFeature, createReducer, on } from '@ngrx/store';
import { ClientConfigurationSuperAdminDto } from 'src/app/models/clientSettings';
import { ClientsDto } from 'src/app/models/clientsDto';
import {
  cityActions,
  clientSettingsActions,
  topicsActions,
} from './news.actions';
import { CitySuperAdminDto } from 'src/app/models/citybaseDto';
import { TopicSuperAdminDto } from 'src/app/models/topics';

export type Status = 'Initial' | 'Loading' | 'Ready' | 'Error';

export const newsFeatureName = 'news';

export interface NewsState {
  status: Status;
  newsClients: ClientsDto[];
  clientSettings: ClientConfigurationSuperAdminDto | undefined;
  cities: CitySuperAdminDto[];
  topics: TopicSuperAdminDto[];
  notAssociatedCities: CitySuperAdminDto[];
  notAssociatedTopics: Pick<TopicSuperAdminDto, 'id' | 'name'>[];
}

export const defaultState: NewsState = {
  status: 'Initial',
  newsClients: [],
  clientSettings: undefined,
  cities: [],
  topics: [],
  notAssociatedCities: [],
  notAssociatedTopics: [],
};

export const newsFeature = createFeature({
  name: newsFeatureName,
  reducer: createReducer(
    defaultState,
    on(
      clientSettingsActions.loadAllClients,
      (state): NewsState => ({ ...state, status: 'Loading' })
    ),
    on(
      clientSettingsActions.loadAllClientsSuccess,
      (state, action): NewsState => ({
        ...state,
        newsClients: action.clients,
        status: 'Ready',
      })
    ),
    on(
      clientSettingsActions.loadAllClientsFailed,
      (state): NewsState => ({ ...state, status: 'Error' })
    ),
    on(
      cityActions.loadAllCities,
      (state): NewsState => ({ ...state, status: 'Loading' })
    ),
    on(
      cityActions.loadAllCitiesSuccess,
      (state, action): NewsState => ({
        ...state,
        status: 'Ready',
        cities: action.cities,
      })
    ),
    on(
      cityActions.loadAllCitiesFailed,
      (state): NewsState => ({ ...state, status: 'Error' })
    ),
    on(
      topicsActions.loadAllTopics,
      (state): NewsState => ({ ...state, status: 'Loading' })
    ),
    on(
      topicsActions.loadAllTopicsSuccess,
      (state, action): NewsState => ({
        ...state,
        status: 'Ready',
        topics: action.topics,
      })
    ),
    on(
      topicsActions.loadAllTopicsFailed,
      (state): NewsState => ({ ...state, status: 'Error' })
    ),
    on(
      clientSettingsActions.loadClientSettings,
      (state): NewsState => ({ ...state, status: 'Loading' })
    ),
    on(
      clientSettingsActions.loadClientSettingsSuccess,
      (state, action): NewsState => {
        const filteredCities = state.cities.filter(
          (city) =>
            !action.settings.preferredCities.some((c) => c.id === city.id)
        );
        const filteredTopics = state.topics.filter(
          (topic) =>
            !action.settings.preferredTopics.some((t) => t.id === topic.id)
        );
        return {
          ...state,
          clientSettings: action.settings,
          status: 'Ready',
          notAssociatedCities: filteredCities,
          notAssociatedTopics: filteredTopics,
        };
      }
    ),
    on(
      clientSettingsActions.loadClientSettingsFailed,
      (state): NewsState => ({ ...state, status: 'Error' })
    ),
    on(
      cityActions.loadNotAssociatedCities,
      (state, action): NewsState => ({
        ...state,
        notAssociatedCities: action.cities,
      })
    ),
    on(
      clientSettingsActions.associateCitiesToClient,
      (state): NewsState => ({ ...state, status: 'Loading' })
    ),
    on(
      clientSettingsActions.associateCitiesToClientSuccess,
      (state, action): NewsState => {
        const notAssociatedCities = state.notAssociatedCities.filter(
          (city) =>
            !action.settings.preferredCities.some((c) => c.id === city.id)
        );
        return {
          ...state,
          status: 'Ready',
          notAssociatedCities: notAssociatedCities,
          clientSettings: action.settings,
        };
      }
    ),
    on(
      clientSettingsActions.associateCitiesToClientFailed,
      (state): NewsState => ({ ...state, status: 'Error' })
    ),
    on(
      clientSettingsActions.removeCitiesFromSettings,
      (state): NewsState => ({ ...state, status: 'Loading' })
    ),
    on(
      clientSettingsActions.removeCitiesFromSettingsFailed,
      (state): NewsState => {
        return {
          ...state,
          status: 'Error',
        };
      }
    ),
    on(
      clientSettingsActions.associateTopicsToClient,
      (state): NewsState => ({ ...state, status: 'Loading' })
    ),
    on(
      clientSettingsActions.associateTopicsToClientSuccess,
      (state, action): NewsState => {
        const notAssociatedTopics = [
          ...state.notAssociatedTopics.filter(
            (topic) =>
              !action.settings.preferredTopics.some((t) => t.id === topic.id)
          ),
        ];
        console.log(notAssociatedTopics);
        return {
          ...state,
          status: 'Ready',
          notAssociatedTopics: [...notAssociatedTopics],
          clientSettings: action.settings,
        };
      }
    ),
    on(
      clientSettingsActions.associateTopicsToClientFailed,
      (state): NewsState => ({ ...state, status: 'Error' })
    ),
    on(
      clientSettingsActions.removeTopicsFromSettings,
      (state): NewsState => ({ ...state, status: 'Loading' })
    ),
    on(
      clientSettingsActions.removeTopicsFromSettingsFailed,
      (state): NewsState => {
        return {
          ...state,
          status: 'Error',
        };
      }
    )
  ),
});

export const {
  selectNewsClients,
  selectClientSettings,
  selectCities,
  selectNotAssociatedCities,
  selectNotAssociatedTopics,
  selectStatus,
} = newsFeature;
