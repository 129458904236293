import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { tap } from 'rxjs';
import { selectLoggedIn } from '../store/auth/auth.reducer';

export const isAuthenticatedGuard: CanActivateFn = (_route, _state, router = inject(Router) ) => {
  return inject(Store).select(selectLoggedIn).pipe(
    tap((loggedIn) => {

      if (!loggedIn) {
        void router.navigateByUrl("/auth/welcome");
      }
    })
  );
};