import { createFeature, createReducer, createSelector, on } from '@ngrx/store';
import {
  authActions,
  // redirectActions
} from './auth.actions';

export const authFeatureName = 'auth';
export interface AuthState {
  loggedIn: boolean;
  token: string | undefined;
  refreshToken: string | undefined;

  isLoggingIn: boolean; //loading
  isRefreshingToken: boolean; //refreshing
}

export const initialAuthState: AuthState = {
  loggedIn: false,
  token: undefined,
  refreshToken: undefined,
  isLoggingIn: false,
  isRefreshingToken: false,
};

export const authFeature = createFeature({
  name: authFeatureName,
  reducer: createReducer(
    initialAuthState,
    on(
      authActions.createNewToken,
      (state): AuthState => ({ ...state, isRefreshingToken: true })
    ),
    on(authActions.loginSuccess, (state, action) => {
      return {
        ...state,
        loggedIn: true,
        token: action.token,
        refreshToken: action.refreshToken,
        isLoggingIn: false,
      };
    }),
    // on(authActions.authenticatingStart, (state): AuthState=>({...state, status: 'AUTHENTICATING'})),
    on(authActions.loggedOut, (state) => {
      return {
        ...state,
        loggedIn: false,
        token: undefined,
        refreshToken: undefined,
        isLoggingIn: false,
      };
    }),
    on(
      authActions.refreshingToken,
      (state): AuthState => ({ ...state, isRefreshingToken: true })
    ),
    on(
      authActions.refreshingTokenSuccess,
      (state, action): AuthState => ({
        ...state,
        isRefreshingToken: false,
        token: action.token,
        refreshToken: action.refreshToken,
        loggedIn: true,
      })
    ),
    on(
      authActions.refreshingTokenFailed,
      (state): AuthState => ({
        ...state,
        isRefreshingToken: false,
        loggedIn: false,
      })
    )
  ),
  extraSelectors: ({
    selectToken,
    selectRefreshToken,
    selectIsRefreshingToken,
  }) => ({
    selectTokenAndRefreshToken: createSelector(
      selectToken,
      selectRefreshToken,
      selectIsRefreshingToken,
      (token, refreshToken, isRefreshingToken) => ({
        token,
        refreshToken,
        isRefreshingToken,
      })
    ),
  }),
});

export const {
  selectIsLoggingIn,
  selectLoggedIn,
  selectToken,
  selectRefreshToken,
  selectTokenAndRefreshToken,
} = authFeature;
// authFeature.selectIsLoggingIn

// export const selectTokenAndRefreshToken = createSelector((selectToken, selectRefreshToken) => ({token: selectToken, refresh}));
