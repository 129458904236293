import { inject } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ClientSettingsService } from 'src/app/services/client-settings.service';
import {
  cityActions,
  clientSettingsActions,
  topicsActions,
} from './news.actions';
import { catchError, combineLatest, mergeMap, of, switchMap } from 'rxjs';
import { CitiesService } from 'src/app/services/cities.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { TopicsService } from 'src/app/services/topics.service';
// import { Router } from '@angular/router';

export const loadingClients$ = createEffect(
  (
    actions$ = inject(Actions),
    clientSettings = inject(ClientSettingsService),
    notification = inject(NotificationService)
  ) => {
    return actions$.pipe(
      ofType(clientSettingsActions.loadAllClients),
      mergeMap(() =>
        clientSettings.getClients().pipe(
          catchError(() => {
            notification.showError(
              'Si è verificato un errore nel caricamento dei dati'
            );
            return of(undefined);
          })
        )
      ),
      switchMap((clients) => {
        if (clients)
          return of(clientSettingsActions.loadAllClientsSuccess({ clients }));
        return of(clientSettingsActions.loadAllClientsFailed());
      })
    );
  },
  { dispatch: true, functional: true }
);

export const loadSettings$ = createEffect(
  (
    actions$ = inject(Actions),
    clientSettings = inject(ClientSettingsService),
    notification = inject(NotificationService)
    // router = inject(Router)
  ) => {
    return actions$.pipe(
      ofType(clientSettingsActions.loadClientSettings),
      mergeMap((action) =>
        clientSettings.getSettingsById(action.client_id).pipe(
          catchError(() => {
            notification.showError(
              'Si è verificato un errore nel caricamento dei dati'
            );
            return of(undefined);
          })
        )
      ),
      switchMap((res) => {
        // void router.navigate([res.clientId, 'cities']);
        if (res) {
          return of(
            clientSettingsActions.loadClientSettingsSuccess({
              settings: res,
            })
          );
        }
        return of(clientSettingsActions.loadClientSettingsFailed());
      })
    );
  },
  { dispatch: true, functional: true }
);

export const loadCities$ = createEffect(
  (
    actions$ = inject(Actions),
    citiesService = inject(CitiesService),
    notification = inject(NotificationService)
  ) => {
    return actions$.pipe(
      ofType(cityActions.loadAllCities),
      mergeMap(() =>
        citiesService.getAdminAll().pipe(
          catchError(() => {
            notification.showError(
              'Si è verificato un errore nel caricamento dei dati'
            );
            return of(undefined);
          })
        )
      ),
      switchMap((cities) => {
        if (cities) {
          return of(cityActions.loadAllCitiesSuccess({ cities }));
        }
        return of(cityActions.loadAllCitiesFailed());
      })
    );
  },
  { dispatch: true, functional: true }
);

export const loadTopics$ = createEffect(
  (
    actions$ = inject(Actions),
    topicService = inject(TopicsService),
    notification = inject(NotificationService)
  ) => {
    return actions$.pipe(
      ofType(topicsActions.loadAllTopics),
      mergeMap(() =>
        topicService.getAdminAll().pipe(
          catchError(() => {
            notification.showError(
              'Operazione fallita',
              'Si è verificato un errore'
            );
            return of(undefined);
          })
        )
      ),
      switchMap((topics) => {
        if (topics) {
          return of(topicsActions.loadAllTopicsSuccess({ topics }));
        }
        return of(topicsActions.loadAllTopicsFailed());
      })
    );
  },
  { functional: true, dispatch: true }
);

export const associateCities$ = createEffect(
  (
    actions$ = inject(Actions),
    clientSettings = inject(ClientSettingsService),
    notification = inject(NotificationService)
  ) => {
    return actions$.pipe(
      ofType(clientSettingsActions.associateCitiesToClient),
      mergeMap((action) =>
        clientSettings.associateCities(action.client_id, action.data).pipe(
          catchError(() => {
            notification.showError(
              'Operazione fallita',
              'Si è verificato un errore'
            );

            return of(undefined);
          })
        )
      ),
      switchMap((settings) => {
        if (settings) {
          return of(
            clientSettingsActions.associateCitiesToClientSuccess({ settings })
          );
        }
        return of(clientSettingsActions.associateCitiesToClientFailed());
      })
    );
  },
  { functional: true }
);

export const removeCities$ = createEffect(
  (
    actions$ = inject(Actions),
    clientSettings = inject(ClientSettingsService),
    notification = inject(NotificationService)
  ) => {
    return actions$.pipe(
      ofType(clientSettingsActions.removeCitiesFromSettings),
      mergeMap((action) =>
        combineLatest([
          clientSettings.removeCities(action.client_id, action.data),
          of(action.client_id),
        ])
      ),
      switchMap((res) =>
        of(clientSettingsActions.loadClientSettings({ client_id: res[1] }))
      ),
      catchError(() => {
        notification.showError(
          'Operazione fallita',
          'Si è verificato un errore'
        );
        return of(clientSettingsActions.removeCitiesFromSettingsFailed());
      })
    );
  },
  { functional: true }
);

export const associateTopics$ = createEffect(
  (
    actions$ = inject(Actions),
    clientSettings = inject(ClientSettingsService),
    notification = inject(NotificationService)
  ) => {
    return actions$.pipe(
      ofType(clientSettingsActions.associateTopicsToClient),
      mergeMap((action) =>
        clientSettings.associateTopics(action.client_id, action.data).pipe(
          catchError(() => {
            notification.showError(
              'Operazione fallita',
              'Si è verificato un errore'
            );

            return of(undefined);
          })
        )
      ),
      switchMap((settings) => {
        if (settings) {
          return of(
            clientSettingsActions.associateTopicsToClientSuccess({ settings })
          );
        }
        return of(clientSettingsActions.associateTopicsToClientFailed());
      })
    );
  },
  { functional: true }
);

export const removeTopics$ = createEffect(
  (
    actions$ = inject(Actions),
    clientSettings = inject(ClientSettingsService),
    notification = inject(NotificationService)
  ) => {
    return actions$.pipe(
      ofType(clientSettingsActions.removeTopicsFromSettings),
      mergeMap((action) =>
        // combineLatest([
        //   clientSettings.removeTopics(action.client_id, action.data),
        //   of(action.client_id),
        // ])
        clientSettings.removeTopics(action.client_id, action.data).pipe(
          switchMap(() =>
            of(
              clientSettingsActions.loadClientSettings({
                client_id: action.client_id,
              })
            )
          ),
          catchError(() => {
            notification.showError(
              'Operazione fallita',
              'Si è verificato un errore'
            );
            return of(clientSettingsActions.removeTopicsFromSettingsFailed());
          })
        )
      )
      // switchMap((res) =>
      //   of(clientSettingsActions.loadClientSettings({ client_id: res[1] }))
      // ),
      // catchError(() => {
      //   notification.showError(
      //     'Operazione fallita',
      //     'Si è verificato un errore'
      //   );
      //   return of(clientSettingsActions.removeTopicsFromSettingsFailed());
      // })
    );
  },
  { functional: true }
);
