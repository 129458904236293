import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from '@angular/common/http';

import { routes } from './app.routes';
import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco } from '@ngneat/transloco';
import * as authEffects from './store/auth/auth.effects';
import * as newsEffect from './store/news/news.effects';
import { authFeature } from './store/auth/auth.reducer';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideToastr } from 'ngx-toastr';
import { TokenInterceptor } from './shared/services/token-interceptor.service';
import { AppComponent } from './app.component';
import { newsFeature } from './store/news/news.reducer';

export const appConfig: ApplicationConfig = {
  providers: [
    AppComponent,
    provideRouter(routes),
    provideHttpClient(withInterceptorsFromDi()),
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TokenInterceptor,
      multi: true,
    },
    provideStore(),
    provideState(newsFeature),
    provideState(authFeature),
    provideEffects([authEffects, newsEffect]),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),

    provideTransloco({
      config: {
        availableLangs: ['it', 'en'],
        defaultLang: 'it',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideAnimations(),
    provideToastr(),
    // provideTinyMce(),

    // importProvidersFrom([
    //     {
    //         provide: MAT_DATE_LOCALE, useValue: it
    //     }
    // ])
  ],
};
