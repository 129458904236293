import { Routes } from '@angular/router';
import { isAuthenticatedGuard } from './guards/auth.guard';

export const routes: Routes = [
  {
    path: 'auth/welcome',
    loadComponent: () =>
      import('./auth/welcome/welcome.component').then(
        (c) => c.WelcomeComponent
      ),
  },

  {
    path: 'news',
    loadChildren: () =>
      import('./loggeduser/loggeduser.route').then((m) => m.appRoutes),
    canActivate: [isAuthenticatedGuard],
    title: 'Admin News',
  },
  {
    path: '**',
    redirectTo: 'auth/welcome',
  },
];
