import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, finalize, map, tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { BackendType, Url } from '../../infrastructure/Url';
import { GenericResponse } from '../../models/genericresponse';
import { IPaginatedResultDto, IPaginationRequest } from "../../infrastructure/PaginationModel";
import { NotificationService } from "./notification.service";

/**
 * @deprecated not use anymore
 */
declare interface RequestCustomOptions {
  authorization?: boolean;
  data?: any;
  headers?: { [header: string]: string | string[] };
}
/**
 * @deprecated not use anymore
 */
const defaultOptions: RequestCustomOptions = {
  authorization: true,
  // eslint-disable-next-line @typescript-eslint/naming-convention
  headers: { Authorization: 'Bearer ' + localStorage.getItem('token') },
  data: null,
};

@Injectable({
  providedIn: 'root',
})
export class RestService {
  _isLoading$ = new BehaviorSubject<boolean>(false);

  private notificationService = inject(NotificationService);

  constructor(private http: HttpClient) {}

  public isLoading$(): Observable<boolean> {
    return this._isLoading$.asObservable();
  }

  /**
   * @deprecated use post$<T>(url: string, data: any, options?: RequestCustomOptions) instead
   * @param {string} url
   * @param {RequestCustomOptions} options @deprecated
   * @param {backendType} backendType The backend to call
   * @returns {Observable<any>}
   */
  post = (
    url: string,
    data: FormData | object | string | undefined = undefined,
    options: RequestCustomOptions = defaultOptions,
    backendType: BackendType = 'Unknown'
  ): Observable<any> => {
    return this.http
      .post(
        Url.UrlConcatenating(Url.GetBackend(backendType), url),
        data,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(
        tap((res) => {
          // eslint-disable-next-line no-console
          console.log('please change this call ', JSON.stringify(res));
        }),
        finalize(() => this._isLoading$.next(false))
      );
  };

  postFormData$<T>(url: string, data: FormData, backendType: BackendType = 'Unknown'): Observable<any>{
    return this.http
      .post<T>(Url.UrlConcatenating(Url.GetBackend(backendType), url), data)
      .pipe(finalize(() => this._isLoading$.next(false)));
  }
  /**
   * Calling post action, with data. Type function was returned observable type.
   * @param url You cannot specify service if you put backend type. If in your url specify backend, backendtype was 'unknown'.
   * For example: /users/my can be changed to my and backendtype was 'users'
   * @param data
   * @param directResponse If backend response was wrapped with generic response ({success: true, data: {your data}}) if false.
   * @param backendType Specify backend to call
   */
  post$<T>(
    url: string,
    data: FormData | any | string = null,
    directResponse: boolean = false,
    backendType: BackendType = 'Unknown',
    responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json'
  ): Observable<T> {
    // this.prepareOptions(options);

    if (directResponse) {
      return this.http
        .post<T>(
          Url.UrlConcatenating(Url.GetBackend(backendType), url),
          data,
          { responseType: responseType as 'json' }
          // options.authorization ? { headers: options.headers } : {}
        )
        .pipe(finalize(() => this._isLoading$.next(false)));
    } else {
      return this.http
        .post<GenericResponse<T>>(
          Url.UrlConcatenating(Url.GetBackend(backendType), url),
          data
          // options.authorization ? { headers: options.headers } : {}
        )
        .pipe(
          map((response) => {
            if (response.success) {
              return response.data;
            } else {
              // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
              if (response.data && (response.data as any).message) {
                // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
                const dataStr: string = (response.data as any)
                  .message as string;

                throw new Error(dataStr);
              }
              throw new Error(response.additionalData);
            }
          }),

          finalize(() => this._isLoading$.next(false))
        );
    }
  }

  postEmptyResponse$(
    url: string,
    data: FormData | any | string | unknown = null,
    backendType: BackendType = 'Unknown'
  ): Observable<string> {
    return this.http
      .post(Url.UrlConcatenating(Url.GetBackend(backendType), url), data, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          //console.error(err);
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  /**
   * @deprecated Use put$<T> instead
   * @param url
   * @param data
   * @param options
   * @param backendType
   */
  put = (
    url: string,
    data: FormData | any | string = null,
    options: RequestCustomOptions = defaultOptions,
    backendType: BackendType = 'Unknown'
  ) => {
    return this.http
      .put(
        Url.UrlConcatenating(Url.GetBackend(backendType), url),
        data,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(
        tap((res) => {
          // eslint-disable-next-line no-console
          console.log('please change this call ', JSON.stringify(res));
        }),
        finalize(() => this._isLoading$.next(false))
      );
  };

  /**
   *
   * @param url
   * @param data
   * @param backendType
   */
  put$<T>(
    url: string,
    data: FormData | any | string | unknown = null,
    backendType: BackendType = 'Unknown'
  ): Observable<T> {
    return this.http
      .put<T>(Url.UrlConcatenating(Url.GetBackend(backendType), url), data)
      .pipe(
        catchError((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  putEmptyResponse$(
    url: string,
    data: FormData | any | string | unknown = null,
    backendType: BackendType = 'Unknown'
  ): Observable<string> {
    return this.http
      .put(Url.UrlConcatenating(Url.GetBackend(backendType), url), data, {
        responseType: 'text',
      })
      .pipe(
        catchError((err) => {
          //console.error(err);
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  patchEmptyResponse$(
    url: string,
    // eslint-disable-next-line @typescript-eslint/ban-types
    data: FormData | object | string | unknown = null,
    options: RequestCustomOptions = defaultOptions,
    backendType: BackendType = 'Unknown'
  ): Observable<string> {
    return this.http
      .patch(
        Url.UrlConcatenating(Url.GetBackend(backendType), url),
        data,
        options.authorization
          ? { headers: options.headers, responseType: 'text' }
          : { responseType: 'text' }
      )
      .pipe(
        catchError((err) => {
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }

  /**
   * @deprecated use patchEmptyResponse$ instead
   * @param url
   * @param data
   * @param options
   * @param backendType
   */
  patch = (
    url: string,
    data: FormData | any | string = null,
    options: RequestCustomOptions = defaultOptions,
    backendType: BackendType = 'Unknown'
  ) => {
    return this.http
      .patch(
        Url.UrlConcatenating(Url.GetBackend(backendType), url),
        data,
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(
        tap((res) => {
          // eslint-disable-next-line no-console
          console.log('please change patch call ', JSON.stringify(res));
        }),
        finalize(() => this._isLoading$.next(false))
      );
  };

  /**
   * @deprecated
   * Chiamata deprecata, DA NON UTILIZZARE PIU'!!!! UTILIZZARE get$
   * @param url
   * @param options
   * @param backendType
   */
  // eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
  get = (
    url: string,
    options: RequestCustomOptions = defaultOptions,
    backendType: BackendType = 'Unknown'
  ): Observable<any> => {
    if (options.data) {
      url += url.indexOf('?') > -1 ? '&' : '?';
      let i = 0;
      for (const key of Object.keys(options.data)) {
        url +=
          // eslint-disable-next-line @typescript-eslint/restrict-plus-operands
          key +
          '=' +
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          options.data[key] +
          (i === Object.keys(options.data).length - 1 ? '' : '&');
        i++;
      }
    }
    return this.http
      .get(
        Url.UrlConcatenating(Url.GetBackend(backendType), url),
        options.authorization ? { headers: options.headers } : {}
      )
      .pipe(
        tap((res) => {
          // eslint-disable-next-line no-console
          console.log(`please change the call ${url}`, JSON.stringify(res));
        }),
        finalize(() => this._isLoading$.next(false))
      );
  };

  get$<T>(
    url: string,
    directResponse = false,
    backendType: BackendType = 'Unknown',
    responseType: 'json' | 'arraybuffer' | 'blob' | 'text' = 'json'
  ): Observable<T> {
    if (directResponse) {
      return this.http
        .get<T>(
          Url.UrlConcatenating(Url.GetBackend(backendType), url),
          { responseType: responseType as 'json' } // options.authorization ? { headers: options.headers } : {}
        )
        .pipe(
          catchError((err) => {
            
            // eslint-disable-next-line no-console
            console.error(err);
            throw err;
          }),
          finalize(() => this._isLoading$.next(false))
        );
    } else {
      return this.http
        .get<GenericResponse<T>>(
          Url.UrlConcatenating(Url.GetBackend(backendType), url)
          //options.authorization ? { headers: options.headers } : {}
        )
        .pipe(
          map((src) => src.data),
          catchError((err) => {
            
            // eslint-disable-next-line no-console
            console.error(err);
            throw err;
          }),
          finalize(() => this._isLoading$.next(false))
        );
    }
  }

  getPaginated$<T>(
    url: string,
    filterPagination: IPaginationRequest,
    backendType: BackendType = 'Unknown'
  ): Observable<IPaginatedResultDto<T>> {

      return this.http
        .get<IPaginatedResultDto<T>>(
          Url.AppendPagination(Url.UrlConcatenating(Url.GetBackend(backendType), url), filterPagination.pageIndex, filterPagination.pageSize, filterPagination.sortField, filterPagination.sortDirection),

        )
        .pipe(
          catchError((err) => {
            this.notificationService.showError('C\'è stato un problema nel caricamento dei dati!');
            console.error(err);
            throw err;
          }),
          finalize(() => this._isLoading$.next(false))
        );

  }




  /**
   * @deprecated
   * @param url
   * @param data
   * @param options
   * @param backendType
   */
  options(url: string): Observable<any> {
    this._isLoading$.next(true);
    return this.http
      .options(environment.backend + (environment.stage || '') + url)
      .pipe(finalize(() => this._isLoading$.next(false)));
  }

  /**
   * @deprecated Use delete$ instead
   * @param url
   * @param data
   * @param backendType
   */
  delete = (url: string, data?: any, backendType: BackendType = 'Unknown') => {
    this._isLoading$.next(true);
    if (data) {
      return this.http
        .request(
          'delete',
          Url.UrlConcatenating(Url.GetBackend(backendType), url),
          { body: data }
        )
        .pipe(
          tap((res) => {
            // eslint-disable-next-line no-console
            console.log('please change this call ', JSON.stringify(res));
          }),
          finalize(() => this._isLoading$.next(false))
        );
    } else {
      return this.http
        .delete(Url.UrlConcatenating(Url.GetBackend(backendType), url))
        .pipe(
          tap((res) => {
            // eslint-disable-next-line no-console
            console.log('please change this call ', JSON.stringify(res));
          }),
          finalize(() => this._isLoading$.next(false))
        );
    }
  };

  delete$(
    url: string,
    backendType: BackendType = 'Unknown'
  ): Observable<string> {
    return this.http
      .delete(Url.UrlConcatenating(Url.GetBackend(backendType), url), {
        responseType: 'text',
      })
      .pipe(
        // tap((res) => {
        //
        //   console.log('r:',res);
        // }),
        catchError((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
          throw err;
        }),
        finalize(() => this._isLoading$.next(false))
      );
  }
}
