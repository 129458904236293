import { createActionGroup, emptyProps, props } from '@ngrx/store';
import { CitySuperAdminDto } from 'src/app/models/citybaseDto';
import { ClientConfigurationSuperAdminDto } from 'src/app/models/clientSettings';
import { ClientsDto } from 'src/app/models/clientsDto';
import { TopicSuperAdminDto } from 'src/app/models/topics';

export const clientSettingsActions = createActionGroup({
  source: 'client settings',
  events: {
    'Load all clients': emptyProps(),
    'Load all clients Success': props<{clients: ClientsDto[]}>(),
    'Load all clients Failed': emptyProps(),

    'Load client settings': props<{client_id: string}>(),
    'Load client settings Success': props<{settings: ClientConfigurationSuperAdminDto}>(),
    'Load client settings Failed': emptyProps(),

    'Associate cities to client': props<{client_id: string, data: number[]}>(),
    'Associate cities to client Success': props<{settings: ClientConfigurationSuperAdminDto}>(),
    'Associate cities to client failed': emptyProps(),

    'Remove cities from settings': props<{client_id: string, data: number[]}>(),
    'Remove cities from settings Success': emptyProps(),
    'Remove cities from settings Failed': emptyProps(),

    'Associate topics to client': props<{client_id: string, data: number[]}>(),
    'Associate topics to client Success': props<{settings: ClientConfigurationSuperAdminDto}>(),
    'Associate topics to client failed': emptyProps(),

    'Remove topics from settings': props<{client_id: string, data: number[]}>(),
    'Remove topics from settings Success': emptyProps(),
    'Remove topics from settings Failed': emptyProps(),

  }
});


export const cityActions = createActionGroup({
  source: 'city',
  events: {
    'Load all cities': emptyProps(),
    'Load all cities Success': props<{cities: CitySuperAdminDto[]}>(),
    'Load all cities Failed': emptyProps(),

    'Load not associated cities': props<{cities: CitySuperAdminDto[]}>(),
  }
})


export const topicsActions = createActionGroup({
  source: 'topic',
  events: {
    'Load all topics': emptyProps(),
    'Load all topics Success': props<{topics: TopicSuperAdminDto[]}>(),
    'Load all topics Failed': emptyProps(),

    'Load not associated topics': props<{cities: TopicSuperAdminDto[]}>(),
  }
})
