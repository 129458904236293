import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {Observable, of} from 'rxjs';
import {catchError, finalize, map, tap} from 'rxjs/operators';
import { RestService } from './rest.service';
import { CONSTANTS } from 'src/app/infrastructure/constants';
import { RefreshTokenResponse } from 'src/app/models/refreshTokenResponse';
// import {CONSTANTS} from '../../constants';
// import {RefreshTokenResponse} from '../../models/refreshtoken.model';




@Injectable({
  providedIn: 'root',
})
export class IdamService {
  constructor(private rest: RestService, private router: Router) {}

  get refreshTokenValue(): string {

    return localStorage.getItem(CONSTANTS.refreshToken) ?? '';
  }

  get tokenValue(): string {
    return localStorage.getItem(CONSTANTS.token) ?? '';
  }

  refreshToken(refreshToken: { token: string; refreshToken: string }): Observable<RefreshTokenResponse | undefined> {
    return this.rest.post$<RefreshTokenResponse | undefined>('refreshToken', refreshToken, true, 'idam');
  }

  createNewToken(token: string): Observable<RefreshTokenResponse | undefined>{
    return this.rest.post$<RefreshTokenResponse | undefined>('new-token', {token},true,  'idam').pipe(
      catchError(err=>{
        console.error('Errore nella creazione nuovo token', err);
        throw err;
      })
    );
  }

  isLogged(): boolean {
    const tokenValue = this.tokenValue;
    return tokenValue !== undefined && tokenValue !== null && tokenValue !== '';
  }

  logout(): Observable<boolean> {
    if (this.isLogged()) {
      return this.rest.post$<{ success: boolean }>('logout', undefined, true, 'idam')
      .pipe(
        tap(() => {
          localStorage.clear();
          void this.router.navigateByUrl('/auth/welcome');
        }),
        map((res: any) => res.success),
        catchError(()=>{
          localStorage.clear();
          void this.router.navigateByUrl('/auth/welcome');
          return of(true);
        }),
        finalize(() => {
          localStorage.clear();
          void this.router.navigateByUrl('/auth/welcome');
        })
      );
    } else {
      localStorage.clear();
      void this.router.navigateByUrl('/auth/welcome');
      return of(true);
    }

  }

  checkToken(): Observable<{ token: string; refreshToken: string } | undefined> {
    if (this.tokenValue && this.tokenValue !== '' ) {
      return this.refreshToken({ token: this.tokenValue, refreshToken: this.refreshTokenValue });
    }
    return of(undefined);

  }

  setToken(token: string, refreshToken: string): void {
    localStorage.setItem(CONSTANTS.token, token);
    localStorage.setItem(CONSTANTS.refreshToken, refreshToken);
  }

  userNameExist(email: string): Observable<boolean> {

    return this.rest.get$<boolean>(`idam/email-exists/${email}`, true);

  }
}
