import { Injectable } from '@angular/core';
import { RestService } from '../shared/services/rest.service';
import { Observable } from 'rxjs';
import { ClientConfigurationSuperAdminDto } from '../models/clientSettings';
import { ClientsDto } from '../models/clientsDto';

@Injectable({
  providedIn: 'root',
})
export class ClientSettingsService {
  constructor(private rest: RestService) {}

  getClients(): Observable<ClientsDto[]>{
    return this.rest.get$<ClientsDto[]>('admin/common/get-clients-with-news', true, 'newsprovider');
  }

  getSettingsById(client_id: string):Observable<ClientConfigurationSuperAdminDto>{
    return this.rest.get$<ClientConfigurationSuperAdminDto>(`admin/client-configuration/get-configuration-by-client/${client_id}`, true, 'newsprovider');
  }

  associateCities(client_id: string, data: number[]): Observable<ClientConfigurationSuperAdminDto>{
    return this.rest.put$<ClientConfigurationSuperAdminDto>(`admin/client-configuration/associate-cities/${client_id}`, data ,'newsprovider');
  }

  removeCities(client_id: string, cities: number[]): Observable<string>{
    let query = '?';
    for(let city of cities){
      query += `cityIds=${city}&`;
    }
    return this.rest.delete$(`admin/client-configuration/delete-cities/${client_id}${query}`, 'newsprovider');
  }

  associateTopics(client_id: string, data: number[]): Observable<ClientConfigurationSuperAdminDto>{
    return this.rest.put$<ClientConfigurationSuperAdminDto>(`admin/client-configuration/associate-topics/${client_id}`, data ,'newsprovider');
  }

  removeTopics(client_id: string, topics: number[]): Observable<string>{
    let query = '?';
    for(let topic of topics){
      query += `topicIds=${topic}&`;
    }
    return this.rest.delete$(`admin/client-configuration/delete-topics/${client_id}${query}`, 'newsprovider');
  }

}
